/*
 * @Author: Musa Tabitay
 * @Date: 2022-04-04 12:21:03
 * @LastEditTime: 2022-04-05 22:20:41
 */

/**
 * 文章接口模块
 */
import request from '@/utils/request'

/**
 * 获取文章详情
 */
export const courseDetail = params => {
  return request({
    method: 'GET',
    url: `/v2/course/course_detail/${params.vid}`
  })
}

/**
  * 获取频道的文章列表
  */
export const getArticles = params => {
  return request({
    method: 'GET',
    url: `/v2/kino/getAllVideo?vid=${params.vid}`
  })
}

/**
 * 获取文章内容详情
 */
export const getArticleById = id => {
  return request({
    method: 'GET',
    url: `/v2/kino/video_detail/${id}`
  })
}
