<!--
 * @Author: Musa Tabitay
 * @Date: 2022-01-05 13:07:39
 * @LastEditTime: 2022-04-23 22:58:24
-->

<template>
  <div class="article-container">
    <div class="main-wrap">
      <!-- 加载中 -->
      <div v-if="loading" class="loading-wrap">
        <van-loading color="#3296fa" vertical>مازمۇن ىزدەلۋدە...</van-loading>
      </div>
      <!-- /加载中 -->

      <!-- 加载完成-文章详情 -->
      <div
        v-else-if="article.article_text || article.article_pic"
        class="article-detail"
      >
        <!-- 文章标题 -->
        <h1 class="article-title">{{ article.title }}</h1>
        <!-- <div class="created_at">
          <van-icon name="clock-o" /> {{ article.created_at }}
        </div> -->
        <!-- /文章标题 -->

        <!-- 文章内容 -->
        <div
          class="content-container"
          v-if="article.is_free == 2 && article.price !== '0.00'"
        >
          <div
            v-if="article.description"
            ref="article-content"
            class="article-content markdown-body free-content"
            v-html="article.description"
          ></div>
          <div class="price_tips">
            <van-button
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              @click="payMoney"
              round
              type="info"
              >تولىعىن {{ article.price }} يۋان تولەپ وقي الاسىز</van-button
            >
          </div>
        </div>
        <template v-else-if="article.is_free == 1">
          <!-- <div style="width: 300px; margin: auto">
            <van-image :src="article.image" />
          </div> -->
          <div
            v-if="article.article_text"
            ref="article-content"
            class="article-content markdown-body"
            v-html="article.article_text"
          ></div>
          <div class="article_pic" v-if="article.article_pic">
            <van-image :src="article.article_pic" />
          </div>
        </template>
        <template v-else>
          <div ref="article-content" class="article-content markdown-body">
            مازمۇن شىعارۋدا قاتەلىك بار، قايتالاي كورىپ شىعىڭىز!
          </div>
        </template>
      </div>
      <!-- /加载完成-文章详情 -->
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { getArticleById } from '@/api/article'
import { pay, payResult } from '@/api/pay'
import { initShareInfo } from '@/utils/util'
import { wechatRedirect } from '@/api/user'
import { getItem } from '@/utils/storage'

export default {
  name: 'ArticleIndex',
  // 给所有的后代组件提供数据
  // 注意：不要滥用，如果一个父组件里面的所有子组件（所有后代组件）都需要的话可以这么传过来
  provide () {
    return {
      artId: this.artId
    }
  },
  props: {
    artId: {
      type: [Number, String, Object],
      required: true
    }
  },
  data () {
    return {
      loading: true,
      article: {}, // 文章详情
      errStatus: 0, // 失败的状态码
      userInfo: {} // 用户信息
    }
  },
  mounted () {
    setTimeout(() => {
      const userInfo = getItem('userInfo')
      if (userInfo) {
        this.userInfo = userInfo
        this.loadArticle()
      }
    }, 600)
  },
  methods: {
    async loadArticle () {
      this.loading = true
      try {
        const { data } = await getArticleById(this.artId)
        document.title = data.title
        this.article = data
        wx.ready(() => {
          initShareInfo({
            title: data.title,
            desc: data.description,
            imgUrl: data.image
          })
        })

        // 如果当前文章状态为2的话要弹出付费提示
        if (parseInt(data.is_free) === 2 && this.userInfo.token) {
          setTimeout(() => {
            this.payMoney()
          }, 1000)
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    // 支付
    async payMoney () {
      const _this = this
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      // 支付请求参数
      const params = {
        goods_name: _this.article.title,
        goods_id: _this.article.id,
        price: _this.article.price,
        parent_id: _this.article.vid,
        openId
      }
      const { code, data } = await pay(params)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.args.timeStamp,
          nonceStr: data.args.nonceStr,
          package: data.args.package,
          signType: data.args.signType,
          paySign: data.args.paySign,
          success (res) {
            if (res.errMsg === 'chooseWXPay:ok') {
              payResult({
                payState: 'success',
                goods_name: _this.article.title,
                goods_id: _this.article.id,
                price: _this.article.price,
                order_number: data.orderInfo.order_number,
                parent_id: _this.article.vid
              })

              setTimeout(() => {
                _this.loadArticle()
              }, 500)
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          cancel: function () {
            _this._userCancel()
          },
          fail: function (res) {
            alert(res.message || 'اقشا تولەۋ ءساتسىز بولدى')
          }
        })
      }
    },

    // 用户取消付款提示
    async _userCancel () {
      try {
        await this.$dialog.confirm({
          title: 'ەسكەرتۋ',
          message:
            'ءسىز اقشا تولەۋدى كۇشىنەن قالدىردىڭىز، قايتالاي اقشا تولەۋدى باسىڭىز!',
          confirmButtonText: 'اقشا تولەۋ',
          cancelButtonText: 'ارتقا قايتۋ'
        })
        this.payMoney()
      } catch (error) {
        wx.closeWindow()
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "./github-markdown.css";
.article-container {
  direction: rtl;
  height: 100vh;
  background-color: #fff;

  .main-wrap {
    overflow-y: scroll;
    background-color: #fff;
  }
  .article-detail {
    position: relative;
    padding-bottom: 50px;
    .article-title {
      font-size: 40px;
      padding: 50px 32px 40px;
      margin: 0;
      color: #3a3a3a;
      text-align: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 30px;
    }

    .created_at {
      font-size: 24px;
      color: #999;
      direction: ltr;
      font-family: Arial, Helvetica, sans-serif;
      padding-left: 10px;
      margin-bottom: 20px;
    }

    .article-content {
      padding: 0 32px;
      text-align: justify;
        font-family: 'KazNet', "UKK RKK1", "Kerwen Tor" ,"UKK TZK1", "UKIJ Tuz Tom", "UKIJ Chiwer Kesme", "ALKATIP Tor Tom", 'ALKATIP Basma Tom', "ALKATIP Jornal Tom", "KazakhSoft Asilya", "KazakhSoft Qaniq", "KazakhSoft Baspa", "KazakhSoft Sulus" !important;
      /deep/ p {
        text-align: justify;
          font-family: 'KazNet', "UKK RKK1", "Kerwen Tor" ,"UKK TZK1", "UKIJ Tuz Tom", "UKIJ Chiwer Kesme", "ALKATIP Tor Tom", 'ALKATIP Basma Tom', "ALKATIP Jornal Tom", "KazakhSoft Asilya", "KazakhSoft Qaniq", "KazakhSoft Baspa", "KazakhSoft Sulus" !important;
      }
    }

    .article_pic {
      padding: 20px;
    }

    .content-container {
      position: relative;
      min-height: 200px;
      overflow: hidden;
      text-align: center;

      .price_tips {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 300px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
        display: flex;
        justify-content: center;
        .van-button {
          margin-top: 200px;
        }
      }
    }
  }

  .loading-wrap {
    padding: 200px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-family: Kerwen Tor;
    direction: rtl;
  }
}
</style>
